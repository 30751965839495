import "../styles/section5.css"
import tab from "../../../images/tab.png"
import { useState, useEffect } from "react"
import axios from "axios";
import async from "async";

import HTTPSServices from "../../../utils/HTTPServices";
import { URLS } from "../../../utils/UrlConstants";

const Section5 = () => {

    function submitForm(){

        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		const phoneNumberRegex = /^[6-9]\d{9}$/;

        setErrorMessage("");
        setSuccessMessage("");

        if(
            name === "" ||
            grade === "" ||
            school === "" ||
            email === "" ||
            address === "" ||
            phone1 === "" ||
            option === 0 ||
            subject === ""
        ) {

            setErrorMessage("* Please fill all the details");
            
        } else if(!emailRegex.test(email)) {
            
            setErrorMessage("* Please enter a valid email");
            
        } else if(!phoneNumberRegex.test(phone1)) {
            
            setErrorMessage("* Please enter a valid phone number");

        } else {

            async
                .auto({
                    
                    saveSchoolRecord: (cb) => {

                        var postObj = {
                            name: name,
                            currentGrade: grade,
                            schoolName: school,
                            emailAddress: email,
                            city: address,
                            contactNumber: phone1,
                            course: option===1 ? "FOR IGCSE (Grade IX and X)": (option === 3 ? "Grade IV to VII" : "AS and A Level Subjects"),
                            subject: subject
                        };

                        HTTPSServices
                            .HTTPPost(
                                URLS.createStudent,
                                postObj, 
                                (err, res) => {
                                
                                    if(err) {

                                        return cb(err, null);

                                    }

                                    if(res.data.status) {

                                        return cb(null, null);

                                    }


                            });

                    },

                    sendEmail: (cb) => {

                        axios.defaults.headers.post['Content-Type'] = 'application/json';
                        
                        axios.post('https://formsubmit.co/ajax/learn@ulrnonline.com', {
                            type: "student",
                            name: name,
                            grade: grade,
                            school: school,
                            email: email,
                            city: address,
                            phone1: phone1,
                            // phone2: phone2,
                            course: option===1 ? "FOR IGCSE (Grade IX and X)": (option === 3 ? "Grade IV to VII" : "AS and A Level Subjects"),
                            subject: subject
                        })
                            .then(response => {

                                console.log("THE RESPONSE:: ", response);

                                return cb(null, null);
                                // setPhone2("")
                                // setMessage(2)
                            })
                            .catch(error => {
                            
                                console.log(error);
                            
                                return cb(error, null);

                            });

                    },

                }, (asyncErr, asyncData) => {

                    if(asyncErr) {

                        setErrorMessage("* Something went wrong. Please try again!");

                    } else {

                        setName("")
                        setGrade("")
                        setSchool("")
                        setSubject("")
                        setOption(0)
                        setEmail("")
                        setAddress("")
                        setPhone1("")

                        setErrorMessage("");
                        setSuccessMessage("* Details submitted successfully!");

                    }



                })


        }


        // if(name.length>0 && email.length>0 && grade.length>0 && option!==0 && subject.length>0 && school.length>0 && address.length>0 && phone1.length>0){
        //     axios.defaults.headers.post['Content-Type'] = 'application/json';
        //     axios.post('https://formsubmit.co/ajax/learn@ulrnonline.com', {
        //         type: "student",
        //         name: name,
        //         grade: grade,
        //         school: school,
        //         email: email,
        //         city: address,
        //         phone1: phone1,
        //         // phone2: phone2,
        //         course: option===1 ? "FOR IGCSE (Grade IX and X)": (option === 3 ? "Grade IV to VII" : "AS and A Level Subjects"),
        //         subject: subject
        //     })
        //         .then(response => {

        //             console.log("THE RESPONSE:: ", response);

        //             setName("")
        //             setGrade("")
        //             setSchool("")
        //             setSubject("")
        //             setOption(0)
        //             setEmail("")
        //             setAddress("")
        //             setPhone1("")
        //             // setPhone2("")
        //             // setMessage(2)
        //         })
        //         .catch(error => console.log(error));
        // } else {
        //     // setMessage(1)
        // }
    }
    
    const [device, setDevice] = useState("laptop")
    const [name, setName] = useState("")
    const [grade, setGrade] = useState("")
    const [school, setSchool] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [phone1, setPhone1] = useState("")
    const [phone2, setPhone2] = useState("")
    const [option, setOption] = useState(0)
    const [subject, setSubject] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const s1=[
        "Additional Mathematics", 
        "Accounting", 
        "Business Studies", 
        "Chemistry", 
        "Computer Science", 
        "Economics", 
        "History", 
        "Media Studies", 
        "Physics", 
        "Travel and Tourism",
    ];
    
    const s2=[
        "Accountancy",
        "Economics", 
        "Mathematics", 
        "Media Studies", 
        "Psychology", 
        "Sociology", 
        "Travel and Tourism", 
    ];

    const s3=[
        "Tamil",
        "Hindi",
        "German",
        "French",
        "Cambridge Primary Checkpoint Preparation Programme",
        "Cambridge Lower Secondary Checkpoint Preparation Programme"
    ];

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }
    }, [])

    var renderSubjectstDropdown = () => {


            if(option === 1) {
    
                return(

                    s1.map((sub) => {

                        return(
                            <li 
                                className="cursor-pointer px-2 dropdown-item"
                                onClick={() => setSubject(sub)}>{sub}
                            </li>
                        )
                        
                    })

                );
    
            } else if(option === 2) {
    
                return(
                    
                    s2.map((sub) => {

                        return(
                            <li 
                                className="cursor-pointer px-2 dropdown-item"
                                onClick={() => setSubject(sub)}>{sub}
                            </li>
                        )
                        
                    })

                );
    
            } else if(option === 3) {
    
                return(
                    
                    s3.map((sub) => {

                        return(
                            <li 
                                className="cursor-pointer px-2 dropdown-item"
                                onClick={() => setSubject(sub)}>{sub}
                            </li>
                        )
                        
                    })

                );
    
            }
    
    };


    return ( 
        <div className="container-md py-5" id="register">
            <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    {device!=="mobile"?<img src={tab} alt="tablet" className="s5img"/>:<></>}
                </div>
                <div className="col-md-6">
                    <h3 className={device==="mobile"?"text-center":""}>Registration Form</h3>
                    <div className={device==="mobile"?"text-center":""}><input value={name} onChange={(event) => setName(event.target.value)} className="s5inputs" placeholder="Student Name"/></div>
                    <div className={device==="mobile"?"text-center":""}><input value={grade} onChange={(event) => setGrade(event.target.value)} className="s5inputs" placeholder="Current Grade"/></div>
                    <div className={(device==="mobile"?"text-center":"") + " dropdown mb-2"}>
                        <button class="btn s5dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {option===1?"FOR IGCSE (Grade IX and X)":option===2?"AS and A Level Subjects":option===3?"Grade IV to VIII": "Select Course"}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li class="cursor-pointer dropdown-item px-2" onClick={() => {setOption(3); setSubject("")}}>Grade IV to VIII</li>
                            <li class="cursor-pointer dropdown-item px-2" onClick={() => {setOption(1); setSubject("")}}>FOR IGCSE (Grade&nbsp;IX&nbsp;and&nbsp;X)</li>
                            <li class="cursor-pointer dropdown-item px-2" onClick={() => {setOption(2); setSubject("")}}>AS and A Level Subjects</li>
                        </ul>
                    </div>
                    <div className={(device==="mobile"?"text-center":"") + " dropdown"}>
                        <button class="btn s5dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {subject.length>0?subject:"Select Subject"}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {/* {option===1?s1.map((sub) => <li className="cursor-pointer px-2" onClick={() => setSubject(sub)}>{sub}</li>):option===2?s2.map((sub) => <li className="cursor-pointer px-2" onClick={() => setSubject(sub)}>{sub}</li>):<li className="px-2">Select Course</li>} */}
                            { renderSubjectstDropdown() }
                        </ul>
                    </div>
                    <div className={device==="mobile"?"text-center":""}><input value={school} onChange={(event) => setSchool(event.target.value)} className="s5inputs" placeholder="School Name"/></div>
                    <div className={device==="mobile"?"text-center":""}><input value={email} onChange={(event) => setEmail(event.target.value)} className="s5inputs" placeholder="Email Address"/></div>
                    <div className={device==="mobile"?"text-center":""}><input value={address} onChange={(event) => setAddress(event.target.value)} className="s5textArea" rows="3" cols="7" placeholder="City" /></div>
                    <div className={device==="mobile"?"text-center":""}><input value={phone1} onChange={(event) => setPhone1(event.target.value)} className="s5inputs" placeholder="Contact Number" /></div>
                    {/* {message===1?<p className="failed">* please fill all fields</p>:message===2?<p className="success">Form submitted. Have a great day</p>:<></>} */}
                    
                    <p className="failed">
                        { errorMessage }
                    </p>
                    <p className="success">
                        { successMessage }
                    </p>
                    
                    <div className={device==="mobile"?"text-center":""}><button className="s5button h5" onClick={submitForm}>Submit</button></div>
                </div>
            </div>
        </div>
     );
}
 
export default Section5;