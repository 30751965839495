import "../styles/section1.css"
import { useNavigate } from "react-router"
import image from "../../../images/landing.png"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

const Section1 = () => {
    
    const [device, setDevice] = useState("laptop")
    const navigate = useNavigate()

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }
    }, [])

    return ( 
    <div className={device==="laptop"?"homeS1 position-relative py-5 pt-5 blueBg":device==="tablet"?"homeS1 d-flex align-items-center blueBg S1tablet position-relative pb-4":"homeS1 position-relative blueBg pb-4"}>
        <div className="container-lg">
            <div className="row">
                <div className="col-sm-6">
                    <h3 className="heading ">
                        REDEFINING LEARNING
                    </h3>
                    <div className="border-top border-5 border-white w-25"></div>
                    <br />
                    <p className="secondary h5">
                    In a world full of possibilities, a student should not be limited by a school’s curriculum. ULRN offers a range of certified online courses for middle and high school students that are recognised worldwide. Students from all boards of study (State, CBSE or International) are welcome here and no prior knowledge is necessary for most of our courses. Pursue your interest areas in depth, learn from qualified tutors at your convenience and differentiate yourself from the pack.

                    <br />
                    <br />
                    ULRN also partners with reputed schools to supplement the subjects offered at school.
                    
                    <br />
                    <br />

                    Join now and let our expert tutors take over.
 
                    </p>
                    <button className="S1button me-4 my-2" onClick={() => {
                        const titleElement = document.getElementById('register')
                        titleElement.scrollIntoView({ behavior: 'smooth' })
                    }}>Register Now</button>
                    <button onClick={() => navigate("/courses")} className="text-decoration-none my-2 S1button">Explore&nbsp;Courses</button>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                    <img src={image} alt="landingimg" className="s1image"/>
                </div>
            </div>
        </div>
    </div> );
}
 
export default Section1;