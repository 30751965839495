import Footer from "./Footer";
import Header from "./Header";
import AdminHeader from "./AdminHeader";
import { useLocation } from "react-router";

const Layout = (props) => {
    const location = useLocation();

    /**
     * ? Method to render the Nav bar based on the page that the user is in
     * */ 
    var renderHeader = () => {

        if(location.pathname.includes("admin-dashboard")) {

            return(

                <AdminHeader />

            );

        } else {

            return(
                <Header route={location.pathname}/>
            );

        }

    };
    // * End of renderHeader()

    
    /**
     * ? Method to render the Footer bar based on the page that the user is in
     * */ 
    var renderFooter = () => {

        if(location.pathname.includes("admin-dashboard")) {

            return null;

        } else {

            return(
                <Footer />
            );

        }

    };
    // * End of renderFooter()



    return ( 
        <>
            <div className={props.className}>
                {/* <Header route={location.pathname}/> */}

                { renderHeader() }

                {props.children}
                
                { renderFooter() }

            </div>
        </>
     );
}
 
export default Layout;