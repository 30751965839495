import "../styles/BlocksSection.css";
import { useState, useEffect } from "react";
import qt from "../../../images/qt.png";
import qb from "../../../images/qb.png";

import { FaChalkboardTeacher, FaSchool } from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { IoMdClipboard } from "react-icons/io";



const BlocksSection = () => {

    const [device, setDevice] = useState("laptop")
    const [width, setWidth] = useState(1200)

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }

        setWidth(window.innerWidth-17)
    }, [])

    return ( 
        <div className="blueBg py-5 position-relative">
            <div className="container-lg text-center py-4 px-5">
                <h3 className="s4heading m-0">HOW DOES IT WORK?</h3>
                <br />
                {/* <p className={device==="laptop"?"whiteText h5 px-5q s4Text":"whiteText h5 s4Text"}>
                    “We are happy to partner with ULRN. ULRN offers online courses for our students in grade VIII and above through which they can explore their interests, learn new topics and take the board exam to receive an accredited certification from the board (Cambridge). We are able to offer new courses now like media studies, that were previously not possible.”
                </p>
                <br />
                <p className="whiteText h5 s4Text font-weight-bold"><b>Ms. Nithya Sundaram</b></p>
                <p className="whiteText h5 s4Text font-weight-bold"><b>Academic Director, CS Academy Group of Schools</b></p> */}
                <div className="w-100 row">
                    <div className="col-12 col-md-3">
                        <div className="block">
                            <span className="block-text">
                                <FaChalkboardTeacher size={45}  className="my-2"/>
                                <br />
                                Virtual classes in the evening &amp; weekends
                            </span>
                        </div>
                    </div>                
                    <div className="col-12 col-md-3">
                        <div className="block">
                            <span className="block-text">
                                <HiOutlineStatusOnline size={45}  className="my-2"/>
                                <br />
                                Two to three live sessions per week
                            </span>
                        </div>
                    </div>                
                    <div className="col-12 col-md-3">
                        <div className="block">
                            <span className="block-text">
                                <BsPencilSquare size={45}  className="my-2"/>
                                <br />
                                Assessments conducted online
                            </span>
                        </div>
                    </div>                
                    <div className="col-12 col-md-3">
                        <div className="block">
                            <span className="block-text">
                                <IoMdClipboard size={45}  className="my-2"/>
                                <br />
                                Board exams taken at school centres
                            </span>
                        </div>
                    </div>                
                </div>
            </div>
        </div>
     );
}
 
export default BlocksSection;