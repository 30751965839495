import React from "react";
import { Route, Navigate } from "react-router-dom";

var Globals = require("../../utils/Globals");

const ProtectedRoute = ({ component: Component, ...rest }) => {
	
	let isAuthenticated = false;

	let user = JSON.parse(
        localStorage.getItem(Globals.ASYNC_STORAGE_KEYS.CORE_DATA)
    );;

	if (user && user['userCoreProperties']) {

		isAuthenticated = true;
	}

	return (
		<Route
			render={(props) =>
				isAuthenticated === true ? (
					<>
						<Component {...props} />
					</>
				) : (
						// <Redirect
						// 	to={{
						// 		pathname: "/",
						// 		state: { from: props.location },
						// 	}}
						// />

                        <Navigate to="/" replace />
					)
			}
			{...rest}
		/>
	);
};

export default ProtectedRoute;
