import "../styles/section2.css"

const Section2 = () => {
    return ( 
        <div className="lightBlueBg py-4">
            <div className="container-md py-5 text-center whiteText">
                <h3>WHY ULRN?</h3>
                <div className="d-flex justify-content-center"><hr className="s2hr w-75"/></div>
                <div className="w-100 d-flex flex-row align-items-center justify-content-center">
                    <p className="blackText h5 secondary">
                        <ol>
                            <span className="black-text-list">✔ Explore areas of interest in depth</span>
                            <br />
                            <span className="black-text-list">✔ Try new subjects before taking the plunge in grade XI</span>
                            <br />
                            <span className="black-text-list">✔ Obtain a globally recognized certification from Cambridge or equivalent organisations</span>
                            <br />
                            <span className="black-text-list">✔ Differentiate yourself and build your profile to support college applications</span>
                        </ol>
                    </p>
                </div>
            </div>
        </div>
     );
}
 
export default Section2;