/**
 * ? HTTPSServices.js
 * 
 * @description :: Contains service methods to perform HTTP POST and GET operations 
 *  
 * */

// ? PLUGINS IMPORT 
var axios = require("axios");


var HTTPServices = {

    /**
     * ? HTTPGet 
     * 
     * @description :: Method to perform HTTP GET operation
     * 
     * @param {string} endPoint
     * @param {callback} callback
     * 
     * @returns {callback}
     * 
     * */ 
    HTTPGet: (endPoint, callback) => {

        axios
            .get(endPoint)
			.then(res => {
				callback(null, res);
			})
			.catch(err => {

				if (err.response && err.response.status && err.response.code == 402) {

					callback(err, null);

				};

				callback(err, null);
			});

    },


    /**
     * ? HTTPPost 
     * 
     * @description :: Method to perform HTTP POST operation
     * 
     * @param {string} endPoint
     * @param {object} postObj
     * @param {callback} callback
     * 
     * @returns {callback}
     * 
     * */ 
    HTTPPost: (endPoint, postObj, callback) => {

        axios
            .post(endPoint, postObj)
            .then(res => {
                callback(null, res);
            })
            .catch(err => {

                if (err.response && err.response.status && err.response.code == 402) {

                    callback(err, null);

                };

                callback(err, null);
            });

    }

};

export default HTTPServices;