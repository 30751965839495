import { useEffect } from "react";
import { Helmet } from "react-helmet";
import BlocksSection from "./sections/BlocksSection";
import Section4 from "./sections/section4";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import WhyUlrn from "./sections/WhyUlrn";

const Courses = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return ( 
        <>
        
            <Helmet>
                <meta charSet="utf-8" />
                <title>Courses | ULRN - Learn By Choice</title>
            </Helmet>

            <Section1 />
            <Section2 />
            {/* <WhyUlrn /> */}
            {/* <Section4 /> */}
            {/* <BlocksSection /> */}
        </>
     );
}
 
export default Courses;