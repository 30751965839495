const Section1 = () => {

    return ( 
    <div className="container py-5 text-center">
        <h3>Schools &amp; Partners</h3>
        <br />
        <p className="secondary h5">
            We support our partner schools to deliver high quality online courses to their students.

            <br /><br />

            We understand that schools are constrained in the range of courses and subjects that can be offered. We partner with schools to supplement the courses available to your students, thus increasing the choices available. Please contact us to create a tailor-made solution for your school. 
        </p>
    </div> );
}
 
export default Section1;