import React, { Component } from 'react';
import { Dropdown } from "react-bootstrap";

import Globals from "../../utils/Globals";
import "./styles/AdminHeader.css";

class AdminHeader extends Component {


    renderLogo = () => {

        return(
            <div>
                <span>
                    ULRN ADMIN
                </span>
            </div>
        );

    }

    adminLogout = () => {

        localStorage.removeItem("CORE_DATA");

        window.location = "/admin-dashboard/login";

    };

    renderDropdown = () => {

        let user = JSON.parse(
            localStorage.getItem("CORE_DATA")
        );

        console.log("USER:: ", user);
    
        if (user) {

            return(

                <Dropdown
                    
                >
                    <Dropdown.Toggle 
                        id="dropdown-basic"
                        className="admin-header-dropdown"
                        style={{
                            backgroundColor: "#9cbdde",
                            border: "none",
                            color: "#292a3c"
                        }}
                    >
                        <span>Hi Admin!</span>
                    </Dropdown.Toggle>
    
                    <Dropdown.Menu>
                        <Dropdown.Item 
                            href="/admin-dashboard/students"
                        >
                            Students
                        </Dropdown.Item>
                        <Dropdown.Item 
                            href="/admin-dashboard/schools"
                        >
                            Schools
                        </Dropdown.Item>
                        <Dropdown.Item 
                            onClick={ () => this.adminLogout() }
                        >
                            Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
    
            );

        }

    }

    componentDidMount = () => {

        // let user = JSON.parse(
        //     localStorage.getItem("CORE_DATA")
        // );

        // if(window.location.pathname !== "/admin-dashbord/login") {

        //     if (user) {
                
        //         // ? DO NOTHING
                
        //     } else {
                
        //         window.location="/admin-dashboard/login";
                
        //     }
        // }


    }


    render() {
        
        return (
        
            <div className="admin-header">
        
                { this.renderLogo() }              
        
                { this.renderDropdown() }

            </div>
        
        );
    }
}

export default AdminHeader;