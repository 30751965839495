import "../styles/section2.css"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import img from "../../../images/cs.png"

const Section2 = () => {

    const [device, setDevice] = useState("laptop")

    const [option, setOption] = useState(2)
    const [contentIndex, setContentIndex] = useState(0)
    const navigate = useNavigate()

    const s2=[
        "Accountancy",
        "Economics", 
        "Mathematics", 
        "Media Studies", 
        "Psychology", 
        "Sociology", 
        "Travel And Tourism", 
    ];
    
    const s1=[
        "Accounting", 
        "Additional Mathematics", 
        "Business Studies", 
        "Chemistry", 
        "Computer Science", 
        "Economics", 
        "History", 
        "Media Studies", 
        "Physics", 
        "Travel And Tourism",
    ];

    const s3=[
        "Tamil",
        "Hindi",
        "German",
        "French",
        "Cambridge Primary Checkpoint Preparation Programme",
        "Cambridge Lower Secondary Checkpoint Preparation Programme"
    ];


    const s2content=[
        "The syllabus engages students with the relevance and applicability of accounting in real-world contexts. Students will explore the role and responsibilities of the accountant, and study areas such as financial accounting and cost and management accounting. Studying this subject helps students to understand, apply, analyse and evaluate accounting information to help decision-making in business and communicate information to stakeholders. These transferable skills are an ideal foundation for further study and a career within accounting or related professions.",
        "Students learn how to explain and analyse economic issues and arguments, evaluate economic information, and organise, present and communicate ideas and judgements clearly. The syllabus covers a range of fundamental economic ideas, including an introduction to the price system and government intervention, international trade and exchange rates, the measurement of employment and inflation, and the causes and consequences of inflation. Students also study the theory of the firm, market failure, macroeconomic theory and policy, and economic growth and development.", 
        "Cambridge International A Level Mathematics develops a set of vital transferable skills. These include the skill of working with mathematical information, as well as the ability to think logically and independently, consider accuracy, model situations mathematically, analyse results and reflect on findings. Learners can apply these skills across a wide range of subjects and these skills equip students well for progression to higher education or directly into employment.", 
        "Cambridge International AS and A Level Media Studies offers learners the chance to develop an understanding and appreciation of the place of media in our everyday lives. The syllabus enables learners to take a hands-on approach to the subject. Through the coursework components - the Foundation Portfolio for AS Level and the Advanced Portfolio for A Level - they create their own media products from planning through to execution. Learners also consider and analyse examples from existing media, examining production processes and technologies and the effects they achieve.", 
        "Cambridge International AS & A Level Psychology is designed to give students an understanding of psychological concepts, theories and research methodologies. The syllabus is suitable for students who have no prior learning in psychology too. The course engages with four core areas of psychology: biological, cognitive, social and learning. It allows students to deepen their knowledge through a choice of two options from clinical psychology, consumer psychology, health psychology and organisational psychology. It also encourages students to consider the relationships between psychological findings and everyday life.", 
        "In a rapidly changing world, Cambridge International AS and A Level Sociology offers learners the opportunity not only to explore the processes that are shaping current trends, but also to develop an understanding of the complexity and diversity of human societies and their continuities with the past. The study of sociology stimulates awareness of contemporary social, cultural and political issues, and focuses on the importance of examining these issues in a rigorous, reasoned and analytical way.", 
        "Cambridge International AS and A Level Travel and Tourism (9395) is ideal for those seeking to specialise in this subject. The course encourages learners to appreciate the changing nature of travel and tourism and understand the importance of sustainability in the development and management of the industry. This syllabus enables learners to understand the concepts and theories in travel and tourism and recognise their impact on people, environments and economies. It develops learners’ practical and research skills through planning and organising an actual tourism event, which will contribute to their further study and working in the industry.", 
    ];


    const s1content=[
        "The Cambridge IGCSE Accounting syllabus introduces learners to the theory and concepts of accounting and the ways in which accounting is used in a variety of modern economic and business contexts. Learners focus on the skills of recording, reporting, presenting and interpreting financial information and build an ideal foundation both for further study and for a future career within the profession.", 
        "Cambridge IGCSE Additional Mathematics supports learners in building competency, confidence and fluency in their use of techniques and mathematical understanding. This course helps learners to develop a feel for quantity, patterns and relationships. Learners will develop their reasoning, problem-solving and analytical skills in a variety of contexts.", 
        "The Cambridge IGCSE Business Studies syllabus develops learners' understanding of business activity in the public and private sectors, and the importance of innovation and change. Learners find out how the major types of business organisation are established, financed and run, and how their activities are regulated. Factors influencing business decision-making are also considered, as are the essential values of cooperation and interdependence.",
        "Cambridge IGCSE Chemistry helps learners to understand the world in which they live and take an informed interest in science and scientific developments. The syllabus includes the basic principles and concepts that are fundamental to the subject, some current applications of chemistry, and a strong emphasis on practical skills.", 
        "Cambridge IGCSE Computer Science is for those students who are interested in computers and would like to develop computational thinking.  This course provides an understanding of the principles of problem-solving and coding using computers. The students are introduced to algorithms and high-level programming languages. They also develop a range of technical skills and the ability to effectively test and evaluate computing solutions.", 
        "The Cambridge IGCSE Economics syllabus develops an understanding of economic theory, terminology and principles. Learners study the economics of different countries and how these interrelate. They also learn to work with simple economics data and to use the tools of economic analysis. Learners apply understanding of economics to current economic issues.", 
        "The Cambridge IGCSE History syllabus looks at some of the major international issues of the nineteenth and twentieth centuries, as well as covering the history of particular regions in more depth. The emphasis is on both historical knowledge and on the skills required for historical research. Learners develop an understanding of the nature of cause and effect, continuity and change, similarity and difference and find out how to use and understand historical evidence as part of their studies. Cambridge IGCSE History will stimulate any learner interested in the past, providing a basis for further study, and also encouraging a lifelong interest in the subject.", 
        "Media Studies is designed for students who have grown up in a rapidly changing global multimedia environment and want to become more literate and critical consumers and producers of culture. Through an interdisciplinary comparative and historical lens, the course defines 'media' broadly as including oral, print, theatrical, photographic, broadcast, cinematic, and digital cultural forms and practices. The course looks at the nature of mediated communication, the functions of media, the history of transformations in media and the institutions that help define media's place in society.",
        "Cambridge IGCSE Physics helps learners to understand the technological world in which they live, and take an informed interest in science and scientific developments. The syllabus includes the basic principles and concepts that are fundamental to the subject, some current applications of physics, and a strong emphasis on practical skills.",
        "Cambridge IGCSE Travel and Tourism is designed to help meet the need for skilled and knowledgeable individuals in this rapidly changing industry. The syllabus provides an understanding of the nature of travel and tourism globally, nationally and locally and develops the concepts, models and theories used within the industry. The course    highlights the importance of sustainability, resilience, destinations and marketing and enhances the learners' analysis, interpretation and evaluation skills."
    ];    

    const s3content = [
        "A Dravidian language widely spoken in the south Indian state of Tamil Nadu, Tamil is one of the most sought-after languages to learn. There is a wealth of literature in Tamil. Many learners look forward to reading these texts in the original, rather than the translation. Our Tamil tutors are native speakers, who have many years of experience teaching the language. These tutors help the learners understand the various grammatical structures. They also help them learn new vocabulary. This helps the learners to converse fluently in Tamil. The tutors also design courses in such a way that students are aware of the use of language in everyday conversation as well.",
        
        
        "We offer beginner, intermediate and advanced levels of programmes to suit the needs of the learner. Our tutors are native speakers of Hindi. They will assess the language level of the student before recommending an appropriate programme. If you are looking for a tutor to help your child with the school lessons, our tutors will ensure that the learner is not only proficient in the language, but also learns the vocabulary and grammar required for their grade.",

        "The classes will focus on functional language skills rather than syllabus. The German course will lead up to the German exam conducted by the Goethe Institute which is a prerequisite to enter German universities. This will also serve as a foundation course for those who wish to take up German for the Cambridge IGCSE exams.",
        
        "This is a structured French language course that allows learners to practise the 4 CECR competencies (reading, writing, listening, and speaking) as well as interaction and reading tasks on a variety of topics. Our qualified language teachers deliver communicative lessons focussing on giving students plenty of French language practice with peers. We follow the Common European Framework issued by the European Commission for language learning - A1 (Basic User) to C1 (Proficient user) for group classes.The online sessions are combined with guided self-study to practise and improve the language skills during the class.",

        "The Cambridge Primary Checkpoint tests meant for 5 to 11 years olds cover all major areas of learning in the Cambridge Primary curriculum frameworks for English, English as a second language, Mathematics and Science. These test results give detailed feedback that helps learners get more clarity about their strengths and weaknesses in the main subjects. Cambridge Primary Checkpoint is fully marked by CIE. Results are given as Cambridge Primary Checkpoint scores. This programme helps the learners to prepare for the Primary Checkpoint tests and address any gaps in their foundation.",

        "The Cambridge Lower Secondary Checkpoint is conducted to assess learners at the end of the lower secondary programme (grade VIII). Cambridge Lower Secondary Checkpoint exams cover English, English as a second language, Mathematics, and Science. Cambridge Lower Secondary Checkpoint is fully marked by CIE. Results are given as Cambridge Lower Secondary Checkpoint scores.This programme helps the learners to prepare for the Lower Secondary Checkpoint tests"
    ];

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }
    }, [])

    var renderDropDownButton = () => {

        if(option === 0) {

            return(
                s1[contentIndex]
            );

        } else if(option === 1) {

            return(
                s2[contentIndex]
            );

        } else if(option === 2) {

            return(
                s3[contentIndex]
            );

        }

    };

    var renderDropDownOptions = () => {

        {option===0?s1.map((cat, index) => <li className="px-2 cursor-pointer" onClick={() => setContentIndex(index)}>{cat}</li>):s2.map((cat, index) => <li className="px-2 cursor-pointer" onClick={() => setContentIndex(index)}>{cat}</li>)}


        if(option === 0) {

            return(

                s1.map((cat, index) => { 
                    return(

                        <li 
                            className="px-2 cursor-pointer dropdown-item"
                            onClick={() => setContentIndex(index)}
                        >
                            {cat}
                        </li>
                    )
                })

            );

        } else if(option === 1) {

            return(

                s2.map((cat, index) => { 
                    return(

                        <li 
                            className="px-2 cursor-pointer dropdown-item"
                            onClick={() => setContentIndex(index)}
                        >
                            {cat}
                        </li>
                    )
                })

            );

        } else if(option === 2) {

            return(

                s3.map((cat, index) => { 
                    return(

                        <li 
                            className="px-2 cursor-pointer dropdown-item"
                            onClick={() => setContentIndex(index)}
                        >
                            {cat}
                        </li>
                    )
                })

            );

        }

    };

    var renderSubjectContent = () => {

        if(option === 0) {

            return(
            
                s1content[contentIndex]

            );

        } else if(option === 1) {

            return(

                s2content[contentIndex]

            );

        } else if(option === 2) {

            return(
                <>
                    {/* <span>
                        Therefore we are offering training in 3 languages (2 Indian - Tamil &amp; Hindi and 1 foreign - German) for students of Grade { contentIndex === 2 ? "6" : "4" } to 8.
                    </span> */}

                    <br />
                    <br />
                    { s3content[contentIndex] }
                </>

            );

        }

    }

    return ( 
        <div className="py-3">
            <div className="container py-4">
                <div className={device==="mobile"?"border-bottom secondary":"border-bottom secondary d-flex justify-content-between"}>
                    <div className="d-flex">
                        <div 
                            className={option===2?"me-2 mb-0 secondary h5 fw-bold s2options s2border":"me-2 fw-bold s2options secondary mb-0 h5"} 
                            onClick={() => {setOption(2); setContentIndex(0)}}
                        >
                                {/* AS and A Level Subjects */}
                                FOR GRADES IV TO VIII
                        </div>
                        <div 
                            className={option===0?"mx-2 mb-0 secondary h5 fw-bold s2options s2border":"mx-2 fw-bold s2options secondary mb-0 h5"} 
                            onClick={() => {setOption(0); setContentIndex(0)}}>
                            {/* FOR IGCSE (Grade&nbsp;IX&nbsp;and&nbsp;X) */}
                            FOR GRADES IX and X
                        </div>
                        <div 
                            className={option===1?"mx-2 mb-0 secondary h5 fw-bold s2options s2border":"mx-2 fw-bold s2options secondary mb-0 h5"} 
                            onClick={() => {setOption(1); setContentIndex(0)}}
                        >
                                {/* AS and A Level Subjects */}
                                FOR GRADES XI and XII
                        </div>
                    </div>
                    {device!=="mobile"?<div class="dropdown d-flex justify-content-end">
                        <button class="s2button pe-2 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            {/* {option===0?s1[contentIndex]:s2[contentIndex]} */}
                            { renderDropDownButton() }
                            &nbsp;
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {/* {option===0?s1.map((cat, index) => <li className="px-2 cursor-pointer" onClick={() => setContentIndex(index)}>{cat}</li>):s2.map((cat, index) => <li className="px-2 cursor-pointer" onClick={() => setContentIndex(index)}>{cat}</li>)} */}
                            { renderDropDownOptions() }
                        </ul>
                    </div>:<></>}
                </div>
                {device==="mobile"?<div class="dropdown d-flex justify-content-end">
                    <button class="s2button pe-2 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        {/* {option===0?s1[contentIndex]:s2[contentIndex]} */}
                        { renderDropDownButton() }
                        &nbsp;
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {/* {option===0?s1.map((cat, index) => <li className="px-2 cursor-pointer" onClick={() => setContentIndex(index)}>{cat}</li>):s2.map((cat, index) => <li className="px-2 cursor-pointer" onClick={() => setContentIndex(index)}>{cat}</li>)} */}
                        { renderDropDownOptions() }
                    </ul>
                </div>:<></>}
                <div className="container-md">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center">
                            <img src={img} alt="course" className="courseImg my-3"/>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <p className="text-center container py-5 secondary h5">
                                {/* {option===0?s1content[contentIndex]:s2content[contentIndex]} */}
                                { renderSubjectContent() }
                            </p>
                        </div>
                    </div>
                </div>
                <div className="text-center"><button className="s2button fw-bold h5" onClick={() => navigate("/#register")}>Register Now</button></div>
            </div> 
        </div>
     );
}
 
export default Section2;