module.exports = {

	APP_NAME: "ULRN Online",

	APP_DESCRIPTION: "ULRN Online - Learn by Choice",

	/* ASYNC STORAGE KEYS */
	ASYNC_STORAGE_KEYS: {
		USER_CORE_PROPERTIES: 'AS_APP_USER_PROPERTIES',
		CORE_DATA: 'CORE_DATA',
	},

	/* ENVIRONMENT */
	ENVIRONMENT: {

		ENV: process.env.NODE_ENV === 'development' ? 'DEV' : 'PRODUCTION',

		DEV: {
			FRONTEND_BASE_URL: 'http://localhost:3000/',
			BACKEND_BASE_URL: 'http://localhost:1337/',
			REACT_APP_GA: '',
			GOOGLE_CLIENT_ID: '',
		},

		PRODUCTION: {
			FRONTEND_BASE_URL: 'https://ulrnonline.com/',
			BACKEND_BASE_URL: 'https://api.ulrnonline.com/',
			REACT_APP_GA: '',
			GOOGLE_CLIENT_ID: '',
		},
	},

};
