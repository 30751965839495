import React, { Component } from 'react';
import HTTPServices from '../../utils/HTTPServices';
import { URLS } from '../../utils/UrlConstants';

import moment from "moment";

import "./Styles/AdminStudents.css";
import { Helmet } from 'react-helmet';


class AdminSchools extends Component {

    constructor(props) {

        super(props);

        this.state = {
            schoolsList: [],
        };
    }


    fetchStudentRecords = () => {

        HTTPServices
            .HTTPGet(
                URLS.getAllSchools,
                (err, response) => {

                    if(err) {

                        console.log("ERROR:: ", err);

                    }

                    if(response?.data) {

                        if(!response?.data?.status) {

                            console.log("ERROR IN RES: FALSE : ", response?.data);

                        } else if(response?.data?.status) {

                            this.setState({
                                schoolsList: response?.data?.data?.schoolsList,
                            });

                        } else {

                            console.log("ERROR:INSIDE: ");

                        }

                    } else {

                        console.log("ERROR:OUTSIDE: ");

                    }

                }
            )

    };

    renderStudentRecords = () => {

        var { schoolsList } = this.state;

        if(schoolsList.length === 0) {
            
            return(

                <div className="w-100 text-center">

                    <span>
                        No School Registrations
                    </span>

                </div>


            );
            
        } else {

            return schoolsList.map((school) => {

                return(
                    
                    <div className="student-item w-md-50">
                        
                        <div className="student-item-row">

                            <span>
                                <b>Name:</b> { school.name }
                            </span>
                            
                            <span>
                                <b>Institution Name:</b> { school.institutionName }
                            </span>

                        </div>
                        
                        <div className="student-item-row">

                            <span>
                                <b>Address:</b> { school.address }
                            </span>

                            <span>
                                <b>Date:</b> { moment(school.createdAt, "x").format("DD/MM/YYYY") }
                            </span>


                        </div>

                        <hr
                            style={{
                                color: "grey",
                                backgroundColor: "grey",
                                height: 1
                            }}
                        />

                        <div className="student-item-row">

                            <span>
                                <b>Email Address:</b> { school.emailAddress }
                            </span>
                            
                            <span>
                                <b>Phone:</b> { school.contactNumber }
                            </span>

                        </div>


                    </div>

                )


            });

        }


    };


    componentDidMount = () => {

        let user = JSON.parse(
            localStorage.getItem("CORE_DATA")
        );


        if (user) {
            
            this.fetchStudentRecords();

        } else {
            
            window.location="/admin-dashboard/login";
            
        }

    };

    render() {
        return (
            <div className="admin-students-wrapper">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>ULRN - Admin</title>
                </Helmet>

                <span className="admin-title-text">
                    SCHOOLS 
                </span>

                <div className="student-items-wrapper">

                    { this.renderStudentRecords() }

                </div>


            </div>
        );
    }
}

export default AdminSchools;