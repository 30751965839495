import React, { Component } from 'react';
import HTTPServices from '../../utils/HTTPServices';
import { URLS } from '../../utils/UrlConstants';

import moment from "moment";

import "./Styles/AdminStudents.css";
import { Helmet } from 'react-helmet';


class AdminStudents extends Component {

    constructor(props) {

        super(props);

        this.state = {
            studentsList: [],
        };
    }


    fetchStudentRecords = () => {

        HTTPServices
            .HTTPGet(
                URLS.getAllStudents,
                (err, response) => {

                    if(err) {

                        console.log("ERROR:: ", err);

                    }

                    if(response?.data) {

                        if(!response?.data?.status) {

                            console.log("ERROR IN RES: FALSE : ", response?.data);

                        } else if(response?.data?.status) {

                            this.setState({
                                studentsList: response?.data?.data?.studentsList,
                            });

                        } else {

                            console.log("ERROR:INSIDE: ");

                        }

                    } else {

                        console.log("ERROR:OUTSIDE: ");

                    }

                }
            )

    };

    renderStudentRecords = () => {

        var { studentsList } = this.state;

        if(studentsList.length === 0) {
            
            return(

                <div className="w-100 text-center">

                    <span>
                        No Student Registrations
                    </span>

                </div>


            );
            
        } else {

            return studentsList.map((student) => {

                return(
                    
                    <div className="student-item w-md-50">
                        
                        <div className="student-item-row">

                            <span>
                                <b>Name:</b> { student.name }
                            </span>
                            
                            <span>
                                <b>Grade:</b> { student.currentGrade }
                            </span>

                        </div>
                        
                        <div className="student-item-row">

                            <span>
                                <b>Course:</b> { student.course }
                            </span>
                            
                            <span>
                                <b>Subject:</b> { student.subject }
                            </span>

                        </div>

                        <div className="student-item-row">

                            <span>
                                <b>School Name:</b> { student.schoolName }
                            </span>
                            
                            <span>
                                <b>City:</b> { student.city }
                            </span>

                        </div>

                        <div className="student-item-row">

                            <span>
                                <b>Date:</b> { moment(student.createdAt, "x").format("DD/MM/YYYY") }
                            </span>

                        </div>

                        <hr
                            style={{
                                color: "grey",
                                backgroundColor: "grey",
                                height: 1
                            }}
                        />

                        <div className="student-item-row">

                            <span>
                                <b>Email Address:</b> { student.emailAddress }
                            </span>
                            
                            <span>
                                <b>Phone:</b> { student.contactNumber }
                            </span>

                        </div>


                    </div>

                )


            });

        }


    };


    componentDidMount = () => {

        let user = JSON.parse(
            localStorage.getItem("CORE_DATA")
        );


        if (user) {
            
            this.fetchStudentRecords();

        } else {
            
            window.location="/admin-dashboard/login";
            
        }

    };

    render() {
        return (
            <div className="admin-students-wrapper">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>ULRN - Admin</title>
                </Helmet>

                <span className="admin-title-text">
                    STUDENTS
                </span>

                <div className="student-items-wrapper">

                    { this.renderStudentRecords() }

                </div>


            </div>
        );
    }
}

export default AdminStudents;