import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import HTTPServices from '../../utils/HTTPServices';
import { URLS } from '../../utils/UrlConstants';

import "./Styles/AdminLogin.css";

class AdminLogin extends Component {

    constructor(props) {

        super(props);
        
        this.state = {
            username: "",
            password: "",
            formErrorText: "",
        };

    }

    handleInputChange = (event) => {

        this.setState({
            [event.target.name]: event.target.value
        })

    };

    handleFormSubmit = (event) => {

        event.preventDefault();

        this.setState({
            formErrorText: "",
        });

        // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        
        if(
            this.state.username === "" ||
            this.state.password === ""
        ) {

            this.setState({
                formErrorText: "* Please enter all the details",
            });

        } else {

            console.log("HELLo")

            var postObj = {
                username: this.state.username,
                password: this.state.password,
            };


            HTTPServices
                .HTTPPost(
                    URLS.adminLogin,
                    postObj,
                    (err, res) => {

                        if(err) {

                            this.setState({
                                formErrorText: "* Error logging in. Please try again!",
                            });


                        }

                        if(res?.data?.status === false) {

                            if(res?.data?.code === 403) {

                                this.setState({
                                    formErrorText: "* Invalid credentials",
                                });

                            } else {

                                this.setState({
                                    formErrorText: "* Error logging in. Please try again!",
                                });

                            }

                        } else if(res?.data?.status) {

                            var CORE_DATA = res?.data?.data?.coreData;
                            
                            localStorage.setItem("CORE_DATA", JSON.stringify(CORE_DATA));

                            window.location = "/admin-dashboard/students";

                        }



                    }
                )

        }


    };

    render() {
        return (
            <div className="admin-login-wrapper">
                
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>ULRN - Admin</title>
                </Helmet>

                <div className="admin-login-block">
                    
                    <span className="login-text">
                        Login
                    </span>

                    <br />
                    <br />

                    <form
                        onSubmit={ (event) => this.handleFormSubmit(event) }
                        className="login-form"
                    >

                        <input 
                            type="text"
                            placeholder="Username"
                            name="username"
                            value={ this.state.username }
                            onChange={ (event) => this.handleInputChange(event) }
                            className="login-input"
                        />

                        <br />

                        <input 
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={ this.state.password }
                            onChange={ (event) => this.handleInputChange(event) }
                            className="login-input"
                        />

                        <br />
                        
                        <span className="login-error-text">
                            { this.state.formErrorText }
                            &nbsp;
                        </span>
                        
                        <br />
                        <br />

                        <button
                            type="submit"
                            className="login-btn"
                        >
                            Submit
                        </button>

                    </form>
                </div>

            </div>
        );
    }

}

export default AdminLogin;