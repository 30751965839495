import logo from './logo.svg';
import './App.css';
import Layout from './components/layout/Layout';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes} from "react-router-dom"
import Home from './components/home/Home';
import Courses from './components/courses/Courses';
import Partners from './components/schoolsAndPartners/Partners';
import ProtectedRoute from './components/layout/ProtectedRoute';
import AdminLogin from './components/AdminDashboard/AdminLogin';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminStudents from './components/AdminDashboard/AdminStudents';
import AdminSchools from './components/AdminDashboard/AdminSchools';

  const App = () => {
    let routes = useRoutes([
      { path: "/", element: <Home /> },
      { path: "/courses", element: <Courses />},
      { path: "/partners", element: <Partners />},
      
      { path: "/admin-dashboard/login", element: <AdminLogin />},
      { path: "/admin-dashboard/students", element: <AdminStudents />},
      { path: "/admin-dashboard/schools", element: <AdminSchools />},
      // ...
    ]);
    return routes;
  };
  
  const AppWrapper = () => {
    return (
      <Router>
        <Layout>
          <App />
        </Layout>
      </Router>
    );
  };

export default AppWrapper;
