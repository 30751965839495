import { useEffect } from "react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";
import Section3 from "./sections/Section3";
import Section4 from "./sections/section4";
import Section5 from "./sections/section5";
import BlocksSection from "./sections/BlocksSection";

const Home = (props) => {

    const location = useLocation()
    useEffect(() => {

        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {
        window.scrollTo(0, 0)
        }
    }, [])

    return ( 
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>ULRN - Learn By Choice</title>
            </Helmet>

            <Section1 />
            <Section3 />
            <Section4 />
            <Section2 />
            <BlocksSection />
            <Section5 />
        </div>
     );
}
 
export default Home;