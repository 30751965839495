import "../styles/section1.css"

const Section1 = () => {
    return ( 
        <div className="py-4">
            <div className="text-center container py-4">
                <h3 className="s1heading">COURSES</h3>
                <br />
                <p className="secondary px-4 h5">
                    
                    The courses on offer now are for students in grades IV and above. These courses are delivered by trainers who are experts in the given subjects. All of them bring in numerous years of experience in their relevant fields thereby making learning an enriching experience. 


                    <br />
                    All our courses are certified by Cambridge University (or) other equivalent bodies, thus ensuring they will make a credible impact on university applications.                   
                    </p>
            </div>
        </div>
     );
}
 
export default Section1;