import "../styles/section4.css"
import { useState, useEffect } from "react"
import qt from "../../../images/qt.png"
import qb from "../../../images/qb.png"

const Section4 = () => {

    const [device, setDevice] = useState("laptop")
    const [width, setWidth] = useState(1200)

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }

        setWidth(window.innerWidth-17)
    }, [])

    return ( 
        <div className="blueBg py-5 position-relative">
            {width>=1000?<img src={qt} alt="quotes" className="s4q1" />:<></>}
            {width>=1000?<img src={qb} alt="quotes" className="s4q2" />:<></>}
            <div className="container-lg text-center py-5 px-5">
                <h3 className="s4heading">WHAT PEOPLE SAY</h3>
                <br />
                <br />
                <p className={device==="laptop"?"whiteText h5 px-5q s4Text":"whiteText h5 s4Text"}>
                    “We are happy to partner with ULRN. ULRN offers online courses for our students in grade VIII and above through which they can explore their interests, learn new topics and take the board exam to receive an accredited certification from the board (Cambridge). We are able to offer new courses now like media studies, that were previously not possible.”
                </p>
                <br />
                <p className="whiteText h5 s4Text font-weight-bold"><b>Ms. Nithya Sundaram</b></p>
                <p className="whiteText h5 s4Text font-weight-bold"><b>Academic Director, CS Academy Group of Schools</b></p>
            </div>
        </div>
     );
}
 
export default Section4;