import { useState, useEffect } from "react"
import axios from "axios";
import async from "async";

import HTTPSServices from "../../../utils/HTTPServices";
import { URLS } from "../../../utils/UrlConstants";

import tab from "../../../images/tab.png";


const Section2 = () => {

    function submitForm(){

        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		const phoneNumberRegex = /^[6-9]\d{9}$/;

        setErrorMessage("");
        setSuccessMessage("");

        if(
            contactName === "" ||
            name === "" ||
            email === "" ||
            address === "" ||
            phone1 === ""
        ) {

            setErrorMessage("* Please Fill all the Details!");
            
        } else if(!emailRegex.test(email)) {
            
            setErrorMessage("* Please enter a valid email address!");
            
        } else if(!phoneNumberRegex.test(phone1)) {
            
            setErrorMessage("* Please enter a valid phone number!");

        } else {

            async
                .auto({
                    
                    saveSchoolRecord: (cb) => {

                        var postObj = {
                            name : contactName,
                            institutionName : name,
                            address : address,
                            emailAddress : email,
                            contactNumber : phone1,
                        };

                        HTTPSServices
                            .HTTPPost(
                                URLS.createSchool,
                                postObj, 
                                (err, res) => {
                                
                                    if(err) {

                                        return cb(err, null);

                                    }

                                    if(res.data.status) {

                                        return cb(null, null);

                                    }


                            });

                    },

                    sendEmail: (cb) => {

                        axios.defaults.headers.post['Content-Type'] = 'application/json';
                        
                        axios.post('https://formsubmit.co/ajax/learn@ulrnonline.com', {
                            type: "school",
                            name: name,
                            email: email,
                            address: address,
                            phone1: phone1,
                        })
                            .then(response => {
                                
                                return cb(null, null);

                            })
                            .catch(error => {
                                
                                console.log("ERROR SUBMITING SCHOOL FORM:", error);

                                return cb(error, null);

                            });

                    },

                }, (asyncErr, asyncData) => {

                    if(asyncErr) {

                        setErrorMessage("* Something went wrong. Please try again!");

                    } else {

                        setName("")
                        setEmail("")
                        setAddress("")
                        setPhone1("")

                        setErrorMessage("");
                        setSuccessMessage("* Details submitted successfully!");

                    }



                })

        }
    }

    const [device, setDevice] = useState("laptop")
    const [contactName, setContactName] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [phone1, setPhone1] = useState("")
    const [phone2, setPhone2] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }
    }, [])

    return ( 
        <div className="container-fluid py-5">
            <div className="row">
                <div className="col-md-6 d-flex justify-content-center">
                    {device!=="mobile"?<img src={tab} alt="tablet" className="s5img"/>:<></>}
                </div>
                <div className="col-md-6">
                    <h3 className={device==="mobile"?"text-center":""}>Registration Form For Schools</h3>
                    <div className={device==="mobile"?"text-center":""}><input value={contactName} onChange={(event) => setContactName(event.target.value)} className="s5inputs" placeholder="Name"/></div>
                    <div className={device==="mobile"?"text-center":""}><input value={name} onChange={(event) => setName(event.target.value)} className="s5inputs" placeholder="Name of the Institution"/></div>
                    <div className={device==="mobile"?"text-center":""}><input value={email} onChange={(event) => setEmail(event.target.value)} className="s5inputs" placeholder="Email Address"/></div>
                    <div className={device==="mobile"?"text-center":""}><textarea value={address} onChange={(event) => setAddress(event.target.value)} className="s5textArea" rows="3" cols="7" placeholder="Address" /></div>
                    <div className={device==="mobile"?"text-center":""}><input value={phone1} onChange={(event) => setPhone1(event.target.value)} className="s5inputs" placeholder="Contact Number" /></div>
                    {/* {message===1?<p className="failed">* please fill all fields</p>:message===2?<p className="success">Form submitted. Have a great day</p>:<></>} */}
                    <p className="failed">
                        { errorMessage }
                    </p>
                    <p className="success">
                        { successMessage }
                    </p>

                    <br />
                    <div className={device==="mobile"?"text-center":""}><button className="s5button h4" onClick={submitForm} value={name} onChange={(event) => setName(event.target.value)}>Submit</button></div>
                </div>
            </div>
        </div>
        );
}
 
export default Section2;