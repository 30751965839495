import Section1 from "./sections/section1";
import Section2 from "./sections/section2";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Partners = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return ( 
        <div>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Schools &amp; Partners |  ULRN - Learn By Choice</title>
            </Helmet>
            <Section1 />
            <Section2 />
        </div>
     );
}
 
export default Partners;