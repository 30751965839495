import "../styles/section3.css"
import laptop from "../../../images/laptop.png"
import bullet from "../../../images/icon.png"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router"

const Section3 = () => {

    const [device, setDevice] = useState("laptop")
    const navigate =useNavigate()

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }
    }, [])

    return ( 
        <div className="container-md py-4">
            <div className="row py-4">
                <div className={device==="mobile"?"col-md-6 text-center":"col-md-6"}>
                    <h3 className="s3heading">COURSES</h3>
                    <div className={device==="mobile"?"d-flex justify-content-center":""}><div className="border-top border-5 s3hr w-25 mb-4"></div></div>
                    <p className="secondary h5">
                        
                    The courses on offer are for students in grades IV and above. We are continuously updating the course catalogue and we will have more courses catering to middle and high school students in the future.

                    <br />
                    <br />

                    The subjects available currently are mentioned in the “Course List”. However, if you have a course or subject in mind that is not on our course list, please write to us and we will assess and revert. Students from all syllabi are welcome. 

                    <br />
                    <br />

                    All our courses are certified by Cambridge University (or) other equivalent bodies, thus ensuring they will make a credible impact on university applications.
                    </p>
                    <br />
                    {/* <div className={device==="mobile"?"d-flex justify-content-center":"d-flex"}>
                        <img src={bullet} alt="bullet" className="s3bullet"/>
                        <p className="fw-bold secondary h5">&nbsp;FOR GRADES VIII / IX / X </p>
                    </div>
                    <div className={device==="mobile"?"d-flex justify-content-center":"d-flex"}>
                        <img src={bullet} alt="bullet" className="s3bullet"/>
                        <p className="fw-bold secondary h5">&nbsp;FOR GRADES XI / XII </p>
                    </div>
                    <br/> */}
                    <button className="s3button whiteText" onClick={() => navigate("/courses")}>Browse All Courses</button>
                </div>
                <div className="col-md-6 d-flex justify-content-center">
                    {device!=="mobile"?<img src={laptop} alt="laptop" className={device==="tablet"?"s3img s3tablet":"s3img s3laptop"}/>:<></>}
                </div>
            </div>
        </div>
     );
}
 
export default Section3;