/**
 * ? UrlConstants.ks
 * 
 * @description :: Contains all the Url based constants
 * 
 * */ 

var Globals = require("./Globals");

var baseUrl = Globals.ENVIRONMENT[Globals.ENVIRONMENT.ENV].BACKEND_BASE_URL;

module.exports = {


    URLS: {

        // ? SCHOOLS 
        getAllSchools: baseUrl + "Schools/getAllSchools",
        createSchool: baseUrl + "Schools/createSchool",

        // ? STUDENTS
        getAllStudents: baseUrl + "Students/getAllStudents",
        createStudent: baseUrl + "Students/createStudent",

        // ? ADMIN
        adminLogin: baseUrl + "admin/adminLogin",

    },

};
