import "./styles/header.css"
import {useNavigate} from "react-router-dom"
import { useEffect, useState } from "react"
import { useLocation } from "react-router"

const Header = (props) => {

    const location = useLocation()

    const [device, setDevice] = useState("laptop")

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }
    }, [])

    const navigate = useNavigate()

    return ( 
        <nav className={"navbar navbar-expand-lg navbar-dark hbgBlue"}>
            <div className="container-lg py-4">
                <img src="../images/Logo.png" alt="logo" className="hlogo" onClick={() => navigate("/")}/>
                <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                <div className={device==="laptop"?"navbar-nav text-center":"navbar-nav"}>
                    <div className={device==="laptop"?"me-5":""}>
                        <a className={location.pathname==="/courses"?"nav-link h5 me-2 d-flex justify-content-end active":"nav-link me-2 d-flex justify-content-end h5"} aria-current="page" href="/courses">Courses</a>
                    </div>
                    <a className={location.pathname==="/partners"?"nav-link h5 me-2 d-flex justify-content-end active":"nav-link me-2 d-flex h5 justify-content-end"} href="/partners">Schools &amp; Partners</a>
                </div>
                </div>
            </div>
        </nav>
     );
}
 
export default Header;