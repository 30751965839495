import "./styles/footer.css"
import { useNavigate } from "react-router";
import {BsInstagram, BsLinkedin} from "react-icons/bs"
import {FaFacebook} from "react-icons/fa"
import { useEffect, useState } from "react";
const Footer = () => {

    const navigate = useNavigate()

    const [device, setDevice] = useState("laptop")

    useEffect(() => {
        if( /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setDevice("mobile")
        }
        const userAgent = navigator.userAgent.toLowerCase();
        if(/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)){
            setDevice("tablet")
        }
    }, [])

    return ( 
        <div className="fbgBlue pt-5 pb-2">
            <div className="container-lg">
                <div className="row">
                    <div className="col-md-5 col-sm-6">
                        <img src="../images/Logo.png" alt="logo" className="flogo" onClick={() => navigate("/")}/>
                        <p className="secondary">
                            ULRN provides high quality certified online courses that can be pursued outside the school’s curriculum. 
                            ULRN enables students to explore their interests in depth, learn from qualified tutors and build their profile.
                        </p>
                        <br/>
                    </div>
                    <div className="col-md-1" />
                    <div className="col-md-3 col-sm-6">
                        <h3 className="fheading">Quick Links</h3>
                        <div className="flinks" onClick={() => navigate("/courses")}>Courses</div>
                        <div className="flinks" onClick={() => navigate("/partners")}>Schools &amp; Partners</div>
                        <br/>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <h3 className="fheading">Contact Us</h3>
                        <a href="https://www.instagram.com" rel="noreferrer" className="text-decoration-none text-white" target="_blank"><BsInstagram className="h-8 mt-2 mb-4 me-2"/></a>
                        <a href="https://www.facebook.com" rel="noreferrer" className="text-decoration-none text-white" target="_blank"><FaFacebook className="h-8 mt-2 mb-4 mx-2"/></a>
                        <a href="https://www.linkedin.com" rel="noreferrer" className="text-decoration-none text-white" target="_blank"><BsLinkedin className="h-8 mt-2 mb-4 mx-2"/></a>
                        <p><a href="tel:+919025051386" className="text-decoration-none text-white">+91 9025051386</a> </p>
                        <a href="mailto:learn@ulrnonline.com" className="text-decoration-none text-white">learn@ulrnonline.com</a>
                    </div>
                </div>
            </div>
            <hr />
            {device!=="mobile"?
            <div className="d-flex justify-content-between secondary align-items-center px-4">
                <div className="d-flex">
                    <div className="mx-1 cursor-pointer copyright-text">Disclaimer |</div>
                    <div className="mx-1 cursor-pointer copyright-text">Privacy Policy |</div>
                    <div className="mx-1 cursor-pointer copyright-text">Terms of Service</div>
                </div>
                <div className="">
                    <div className="copyright-text">Copyright © 2021 ULRN | All Rights Reserved</div>
                    <div className="copyright-text text-end"><a href="https://therushrepublic.com" rel="noreferrer" target="_blank" className="text-white text-decoration-none cursor-pointer">Design : Rush Republic</a></div>
                </div>
            </div>:<div className="secondary px-4">
                <div className="">
                    <div>Copyright © 2021 ULRN | All Rights Reserved</div>
                    <div className="text-center"><a href="https://therushrepublic.com" rel="noreferrer" target="_blank" className="text-white text-decoration-none cursor-pointer">Design : Rush Republic</a></div>
                </div>
                <div className="d-flex my-2">
                    <div className="mx-1 cursor-pointer">Disclaimer</div>
                    <div className="mx-1 cursor-pointer">Privacy Policy</div>
                    <div className="mx-1 cursor-pointer">Terms of Service</div>
                </div>
            </div>}
        </div>
     );
}
 
export default Footer;